<template>
	<div class="addFeighttool">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<div class="baseInformtion">
				<div class="infortion">基本信息</div>

				<el-form-item label="活动名称" prop="Name">
					<el-input disabled v-model="ruleForm.Name" placeholder="最多输入20个字" style="width:250px" clearable></el-input>
				</el-form-item>

				<el-form-item label="活动时间" prop="IsPermanent">
					<el-radio-group disabled v-model="ruleForm.IsPermanent">
						<el-radio :label="0">指定时间
							<el-date-picker disabled type="date" :picker-options="pickerOptions0" placeholder="选择日期" v-model="ruleForm.StartTime"
							 style="width: 250px;" value-format="yyyy-MM-dd" format="yyyy-MM-d"></el-date-picker> ~
							<el-date-picker disabled type="date" placeholder="选择日期" :picker-options="pickerOptions0" v-model="ruleForm.EndTime" style="width: 250px;"
							 value-format="yyyy-MM-dd" format="yyyy-MM-dd"></el-date-picker>

						</el-radio>
						</br>
						<el-radio :label="1" style="margin-top:20px">永久有效</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="包邮规则" prop="FullMoney">
					消费满
					<input  disabled v-model="ruleForm.FullMoney" style="margin:0 10px;padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
					 @keydown="handleInput2" type="number">元
				</el-form-item>
			</div>

			<div class="baseInformtion" style="padding-bottom: 70px;">
				<div class="infortion">活动信息</div>

				<div class="filter-container">
					<div class="filter-item" style="width:100px;text-align: right;vertical-align: top;"><span class="tablered">*</span>包邮区域:</div>

					<!-- <div class="filter-item" style="vertical-align: top;color:rgb(64, 158, 255);cursor: pointer;" @click="selectAreas">选择包邮区域</div> -->
				</div>

				<div style="margin:0px 0 20px 130px;width:100%" v-if="ruleForm.AreaNames.length">
					<span v-for="(item,index) in ruleForm.AreaNames" :key="index" style="margin-right: 10px;">{{item}}</span>
				</div>

				<div class="filter-container">
					<div class="filter-item" style="width:100px;text-align: right;vertical-align: top;"><span class="tablered">*</span>包邮商品:</div>
					<!-- <div class="filter-item" style="vertical-align: top;color:rgb(64, 158, 255);cursor: pointer;" @click="slectProduct">选择包邮商品</div> -->
				</div>

				<div style="width:1100px;margin-left: 130px;">
					<el-table :data="allData" style="width:1100px" v-if="allData.length">
						<el-table-column prop="Name" label="商品">
						</el-table-column>
						<el-table-column prop="ProductPrice" label="价格">
						</el-table-column>
						<el-table-column prop="Stock" label="库存">
						</el-table-column>						

					</el-table>

					<el-pagination v-if="TotalNum" style="margin-top:20px;float:right;" @size-change="handleSizeChangenum"
					 @current-change="handleCurrentChangenum" :current-page="currentPagenum" :page-sizes="[5, 10,15,20,25]" :page-size="pageSizenum"
					 layout="total, sizes, prev, pager, next, jumper" :total="TotalNum">
					</el-pagination>
				</div>


			</div>

		</el-form>


		<!-- 选择地区 -->

		<el-dialog title="选择包邮区域" width="700px" :visible.sync="SetDialogVisible" @opened="muneFun">

			<el-tree :data="menuList" :props="{children: '', label: 'name'}" show-checkbox default-expand-all
			 :default-checked-keys="slectKeys" node-key="id"  ref="tree" highlight-current style="height:400px;overflow: auto;">
			</el-tree>

			<div slot="footer" class="dialog-footer">
				<el-button @click="SetDialogVisible=false">取 消</el-button>
				<el-button type="primary" @click="submitEdit">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 选择商品弹框 -->
		<el-dialog :visible.sync="selectVisables" width="1300px" title="选择商品">
			<SelectfrightProduce @getSelectList="getSelectList" :productData="productData"></SelectfrightProduce>
		</el-dialog>

		<div class="footer-tools">
			<el-button style="width:200px;margin-right: 10px;" type="primary" @click="canselCopear">返 回</el-button>
		</div>

	</div>
</template>

<script>
	import {
		activityFreeDeliverySave,
		activityFreeDeliveryInfo
	} from '@/api/goods'

	import axios from 'axios';
	import SelectfrightProduce from '@/components/SelectMulProduce/SelectfrightProduce.vue'

	export default {
		components: {
			SelectfrightProduce
		},
		data() {
			return {
				slectKeys:[],
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的 
					}
				},
				TotalNum: 0,
				currentPagenum: 1,
				pageSizenum: 5,
				productData: [],
				selectVisables: false,
				tableData: [],
				recordData: {},
				menuList: [],
				SetDialogVisible: false,
				ruleForm: {
					Name: '',
					IsPermanent: '',
					StartTime: '',
					EndTime: '',
					FullMoney: '',
					AreaIds: [],
					AreaNames: [],
					ProductIdList: []

				},
				rules: {
					Name: [{
							required: true,
							message: '请输入活动名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 20,
							message: '长度在 1 到 20 个字符',
							trigger: 'blur'
						}
					],
					IsPermanent: [{
						required: true,
						message: '请选择活动时间',
						trigger: 'change'
					}],
					FullMoney: [{
						required: true,
						message: '请填写包邮规则',
						trigger: 'blur'
					}],
				},
				allData: [],
				toolId: null,

			}
		},

		beforeMount() {
			this.toolId = this.$route.query.Id
			this.getstartData();
			this.getDistrict();
		},
		methods: {
			getDistrict(){
				axios.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v=' + new Date().getTime()).then(res => {
					this.menuList = res.data;
				})
			},
			// 初始化数据
			async getstartData() {
				try {
					if (this.toolId) {
						let result = await activityFreeDeliveryInfo({
							Id: this.toolId
						})
						this.ruleForm = result.Result
						if (this.ruleForm.IsPermanent) {
							this.ruleForm.IsPermanent = 1
						} else {
							this.ruleForm.IsPermanent = 0
						}

						this.productData = result.Result.ProductIdList
						this.productData.map(item=>{
							item.ProductId=item.Id;
							item.keys=item.Id;
						})
						
						let dataStart = this.pageSizenum * (this.currentPagenum - 1);
						let dataEnd = this.pageSizenum * this.currentPagenum
						this.allData = this.productData.slice(dataStart, dataEnd);
						this.TotalNum = this.productData.length
						this.slectKeys = result.Result.AreaIds
						
					}

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			// 取消操作
			canselCopear() {
				this.$router.push({
					path: '/market/frightTool'
				});

			},
			// 保存操作
			saveToolData(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						if (!this.ruleForm.AreaNames.length) {
							this.$message({showClose: true,
								type: 'error',
								message: '请选择包邮区域!'
							});

							return
						}

						if (!this.productData.length) {
							this.$message({showClose: true,
								type: 'error',
								message: '请选择包邮商品!'
							});

							return
						}

						let data = {
							Id: 0,
							Name: this.ruleForm.Name,
							IsPermanent: this.ruleForm.IsPermanent,
							StartTime: this.ruleForm.StartTime,
							EndTime: this.ruleForm.EndTime,
							FullMoney: this.ruleForm.FullMoney,
							AreaIds: this.ruleForm.AreaIds,
							AreaNames: this.ruleForm.AreaNames,
							ProductIdList: this.ruleForm.ProductIdList
						}

						let result = await activityFreeDeliverySave(data)

						if (result.IsSuccess) {
							this.$message({showClose: true,
								type: 'success',
								message: '保存成功!'
							});
							this.$router.push({
								path: '/market/frightTool'
							});
						}

					} else {
						console.log('error submit!!');
						return false;
					}
				});


			},
			getSelectList(ishow, val) {
				this.productData = val;
				this.selectVisables = ishow
				this.ruleForm.ProductIdList = []
				let dataStart = this.pageSizenum * (this.currentPagenum - 1);
				let dataEnd = this.pageSizenum * this.currentPagenum
				this.allData = this.productData.slice(dataStart, dataEnd);
				this.TotalNum = val.length
				this.productData.map(item => {
					this.ruleForm.ProductIdList.push(item.ProductId)
				})

			},

			// 切换显示条数
			handleSizeChangenum(val) {
				this.pageSizenum = val
				let dataStart = this.pageSizenum * (this.currentPagenum - 1)
				let dataEnd = this.pageSizenum * this.currentPagenum
				this.allData = this.productData.slice(dataStart, dataEnd);

			},
			// 翻页
			handleCurrentChangenum(val) {
				this.currentPagenum = val;
				let dataStart = this.pageSizenum * (this.currentPagenum - 1);
				let dataEnd = this.pageSizenum * this.currentPagenum
				this.allData = this.productData.slice(dataStart, dataEnd);
			},

			// 选择包邮产品
			slectProduct() {				
				this.selectVisables = true;
			},

			// 选择包邮工具
			selectAreas() {
				this.SetDialogVisible = true;
			},
			muneFun() {
				let that = this;
			},

			// 删除子商品
			delectContect(record, index) {
				this.productData = this.productData.filter(item => {
					return item.ProductId != record.ProductId
				})
				let dataStart = this.pageSizenum * (this.currentPagenum - 1);
				let dataEnd = this.pageSizenum * this.currentPagenum
				this.allData = this.productData.slice(dataStart, dataEnd);
				this.TotalNum = this.productData.length

			},

			// 确定事件
			submitEdit() {
				this.jurisdictionList = [];
				this.ruleForm.AreaIds = [];
				this.ruleForm.AreaNames = [];
				let arrs = this.$refs.tree.getCheckedNodes();
				console.log(this.$refs.tree, arrs, '地址')

				this.SetDialogVisible = false;
				arrs.map(item => {
					this.ruleForm.AreaIds.push(item.id)
					this.ruleForm.AreaNames.push(item.name)
				})


			},

			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null

			},
			guid() {
				var a = function() {
					return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
				};
				return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
			}

		}
	}
</script>

<style lang="less">
	.addFeighttool {
		.baseInformtion {
			width: 100%;
			background: #fff;
			padding: 10px;
			margin-bottom: 12px;

			.infortion {
				margin-bottom: 20px;
				padding: 10px 0;
				border-bottom: 1px solid #ccc;
			}
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.tablered {
			color: red;
			vertical-align: -3px;
			margin-right: 5px;
		}

		.footer-tools {
			width: 100%;
			background: #fff;
			position: fixed;
			bottom: 0;
			background: #fff;
			padding: 10px 0;
			text-align: center;
		}
	}
</style>
